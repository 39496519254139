import ConfiguratorCart from "views/ConfiguratorCart"
import ConfiguratorLayout from "components/Configurator/Layout"
import { PageProps } from "gatsby"
import React from "react"
import SEO from "components/seo"

type DataProps = {}

const ConfiguratorCartPage: React.FC<PageProps<DataProps>> = () => (
  <ConfiguratorLayout activePageId="cart">
    <SEO title="Configurator Cart" />
    <ConfiguratorCart />
  </ConfiguratorLayout>
)

export default ConfiguratorCartPage
